import styled from 'styled-components';
import { mediaInv } from '../ui/common';
import { colors } from '../../../ui/common';

export const BenefitVideo = styled.div`
  position: relative;
  max-width: 562px;
  margin: auto;
  .gatsby-image-wrapper {
    border-radius: 40px;
  }
  ${mediaInv.phone`
  .gatsby-image-wrapper img {
    object-position: left;
  }
  `}
`;

export const BenefitLink = styled.a`
  font-family: 'HelveticaNeueCyr';
  border: 1px solid rgba(95, 110, 255, 0.85);
  border-radius: 500px;
  width: 268px;
  height: 38px;
  color: ${colors.color17};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto 0 auto;
  transition: 0.2s;
  font-size: 18px;
  &:hover {
    border-color: #545cb3;
    color: #60656e;
  }
`;

export const BenButton = styled.div`
  font-family: 'HelveticaNeueCyr';
  position: absolute;
  cursor: pointer;
  background: ${colors.color18};
  border-radius: 43px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 176px;
  height: 66px;
  display: flex;
  align-items: center;
  color: white;
  transition: 0.2s;
  path {
    transition: 0.3s;
  }
  & > span {
    margin: 0 10px 0 16px;
    font-size: 18px;
  }
  &:hover {
    background: #434db3;
  }
`;

export const Title = styled.h2`
  font-family: 'Craftwork Grotesk';
  text-align: center;
  color: ${colors.color17};
  font-size: 56px;
  line-height: 56px;
  margin-bottom: 16px;
  ${mediaInv.phone`
  font-size: 28px;
  line-height: 31px;
  `}
`;

export const Text = styled.p`
  font-family: 'HelveticaNeueCyr';
  color: ${colors.color17};
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`;

export const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  margin-top: -40px;
  .CloseVideo {
    cursor: pointer;
    position: absolute;
    right: 35px;
    top: 70px;
    z-index: 15;
    transition: 0.2s;
    display: none;
  }
  .CloseVideo.active {
    display: block;
  }
  .CloseVideo.active + iframe {
    display: block;
  }
  .CloseVideo:hover {
    opacity: 0.7;
  }
  ${mediaInv.phone`
  padding-top: 70px;
  margin-top: -70px;
  .CloseVideo {
    width: 50px;
    right: 10px;
    top: 10px;
  }
`}
`;

export const PureVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  border: 0;
  display: none;
`;
