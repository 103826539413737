import styled from 'styled-components';
import { mediaInv } from '../ui/common';
import { colors } from '../../../ui/common';

export const Title = styled.h2`
  font-family: 'Craftwork Grotesk';
  color: ${colors.color19};
  font-size: 56px;
  line-height: 56px;
  margin: 200px auto 45px auto;
  text-align: center;
  max-width: 1096px;
  ${mediaInv.phone`
  font-size: 28px;
  line-height: 31px;
  margin-top: 150px;
`}
`;

export const VirusesCont = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaInv.medium`
     flex-wrap: wrap;
     margin-bottom: -35px;
  `}
`;

export const ItemVirus = styled.figure`
  margin: 0;
  width: 31.3%;
  figcaption {
    text-align: center;
    margin-bottom: 40px;
    span {
      display: block;
    }
    .virus1 {
      font-size: 28px;
      line-height: 31px;
      font-family: 'Craftwork Grotesk';
      margin: 15px 0;
      font-weight: 700;
      @media (max-width: 599px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    .virus2 {
      font-family: 'HelveticaNeueCyr';
      font-size: 24px;
      line-height: 28px;
      @media (max-width: 599px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  ${mediaInv.medium`
     width: 100%;
     .gatsby-image-wrapper {
      height: 150px;
      border-radius: 40px;
     }
     
  `}
`;
