import React, { useRef, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import getFluid from '../utils/get-fluid';
import InDetails from '../components/Products/InDetails';
import AlwaysAround from '../components/Products/AlwaysAround';
import BuyTop from '../components/Products/BuyTop';
import Benefit from '../components/Products/Benefit';
import EvaBreeze from '../components/Products/EvaBreeze';
import EvaViruses from '../components/Products/EvaViruses';
import HowUse from '../components/Products/HowUse';
import WhyEffective from '../components/Products/WhyEffective';
import FAQNew from '../components/Products/FAQNew';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import PopUpAdd from '../components/Products/PopUpAdd';
import pagesInfo from '../domain/pages-info';
import NonConfigurableProduct from '../components/Products/Info/NonConfigurableProduct';
import DesignInfo from '../components/Products/Info/DesignInfo';
import InfoDesignIcon from '../components/Products/Info/icons/designInfo.svg';
import Doings from '../components/Products/Info/Doings';
import Bacteria from '../components/Products/Info/icons/bacteria.svg';
import Surf from '../components/Products/Info/icons/surf.svg';
import Oxygen from '../components/Products/Info/icons/oxygen.svg';
import Oxygen2 from '../components/Products/Info/icons/oxygen2.svg';
import { ContBig } from '../components/Products/ui/common';

const promo = graphql`
  {
    right: file(relativePath: { eq: "product-page/evapure/promo1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    promomob: file(relativePath: { eq: "product-page/evapure/promo-mob.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    EvaPurekills: file(
      relativePath: { eq: "product-page/evapure/evakills.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    why1: file(relativePath: { eq: "product-page/evapure/why1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    why2: file(relativePath: { eq: "product-page/evapure/why2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    howuse1: file(relativePath: { eq: "product-page/evapure/howuse1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    howuse2: file(relativePath: { eq: "product-page/evapure/howuse2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    howuse3: file(relativePath: { eq: "product-page/evapure/howuse3.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    howuse4: file(relativePath: { eq: "product-page/evapure/howuse4.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    breeze: file(relativePath: { eq: "product-page/evapure/breeze.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evavirus1: file(
      relativePath: { eq: "product-page/evapure/evavirus1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evavirus2: file(
      relativePath: { eq: "product-page/evapure/evavirus2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evavirus3: file(
      relativePath: { eq: "product-page/evapure/evavirus3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evapure1: file(
      relativePath: { eq: "product-page/evapure/gallery/evapure1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evapure2: file(
      relativePath: { eq: "product-page/evapure/gallery/evapure2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evapure3: file(
      relativePath: { eq: "product-page/evapure/gallery/evapure3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evapure4: file(
      relativePath: { eq: "product-page/evapure/gallery/evapure4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evapure5: file(
      relativePath: { eq: "product-page/evapure/gallery/evapure5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evapure6: file(
      relativePath: { eq: "product-page/evapure/gallery/evapure6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evapure7: file(
      relativePath: { eq: "product-page/evapure/gallery/evapure7.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const info = pagesInfo.evapure;

export default function EvaPure() {
  const infoRef = useRef(null);
  const [selected, setData] = useState({});

  return (
    <StaticQuery
      query={promo}
      render={(data) => (
        <main>
          <NavBar />
          <BuyTop
            data={selected}
            targetRef={infoRef}
            image={data.right.childImageSharp.gatsbyImageData}
            imagemob={data.promomob.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="evapure.buytop.right.title" />}
            text={<FormattedMessage id="evapure.buytop.right.text" />}
            text1={<FormattedMessage id="evapure.buytop.more.1" />}
            text2={<FormattedMessage id="evapure.buytop.more.2" />}
            text3={<FormattedMessage id="evapure.buytop.more.scroll" />}
            text4={<FormattedMessage id="evapure.buytop.add" />}
          />
          <Benefit
            image={data.EvaPurekills.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="evapure.benefit.right.title" />}
            text={<FormattedMessage id="evapure.benefit.right.text" />}
            link={<FormattedMessage id="evapure.benefit.right.link" />}
          />
          <ContBig>
            <NonConfigurableProduct
              data={info}
              productName={info.title}
              fluids={
                new Map([
                  [
                    'common',
                    [
                      'evapure2',
                      'evapure1',
                      'evapure3',
                      'evapure4',
                      'evapure5',
                      'evapure6',
                      'evapure7',
                    ].map((key) => getFluid(data, key)),
                  ],
                ])
              }
              ref={infoRef}
              onChange={setData}
              bigCont="is"
            />
          </ContBig>
          <ContBig>
            <DesignInfo
              image={InfoDesignIcon}
              text={<FormattedMessage id="evapure.designinfo.right.text1" />}
            />
            <Doings
              images={[Bacteria, Surf, Oxygen, Oxygen2]}
              texts={[
                <FormattedMessage id="evapure.doings.right.text1" />,
                <FormattedMessage id="evapure.doings.right.text2" />,
                <FormattedMessage id="evapure.doings.right.text3" />,
                <FormattedMessage id="evapure.doings.right.text4" />,
              ]}
            />
          </ContBig>

          <InDetails />

          <WhyEffective
            image1={data.why1.childImageSharp.gatsbyImageData}
            image2={data.why2.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="evapure.whys.title" />}
            text1={<FormattedMessage id="evapure.whys.text1" />}
            text2={<FormattedMessage id="evapure.whys.text2" />}
            text3={<FormattedMessage id="evapure.whys.text3" />}
            text4={<FormattedMessage id="evapure.whys.text4" />}
            text5={<FormattedMessage id="evapure.whys.text5" />}
            text6={<FormattedMessage id="evapure.whys.text6" />}
            text7={<FormattedMessage id="evapure.whys.text7" />}
            text8={<FormattedMessage id="evapure.whys.text8" />}
            text9={<FormattedMessage id="evapure.whys.text9" />}
            link={<FormattedMessage id="evapure.whys.link" />}
          />

          <AlwaysAround
            title1={<FormattedMessage id="evapure.around.title1" />}
            title2={<FormattedMessage id="evapure.around.title2" />}
            aroundTexts={[
              <FormattedMessage id="evapure.around.text1" />,
              <FormattedMessage id="evapure.around.text2" />,
              <FormattedMessage id="evapure.around.text3" />,
            ]}
            textSlides={[
              <FormattedMessage id="evapure.around.slide1" />,
              <FormattedMessage id="evapure.around.slide2" />,
              <FormattedMessage id="evapure.around.slide3" />,
            ]}
          />

          <HowUse
            images={[
              data.howuse1.childImageSharp.gatsbyImageData,
              data.howuse2.childImageSharp.gatsbyImageData,
              data.howuse3.childImageSharp.gatsbyImageData,
              data.howuse4.childImageSharp.gatsbyImageData,
            ]}
            title={<FormattedMessage id="evapure.howuse.title" />}
            HowTexts={[
              <FormattedMessage id="evapure.howuse.text1" />,
              <FormattedMessage id="evapure.howuse.text2" />,
              <FormattedMessage id="evapure.howuse.text3" />,
              <FormattedMessage id="evapure.howuse.text4" />,
            ]}
            link={<FormattedMessage id="evapure.howuse.link" />}
            text={<FormattedMessage id="evapure.howuse.warning1" />}
            text2={<FormattedMessage id="evapure.howuse.warning2" />}
            step={<FormattedMessage id="evapure.howuse.step" />}
          />

          <EvaBreeze
            image={data.breeze.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="evapure.breeze.title" />}
            title2={<FormattedMessage id="evapure.breeze.title2" />}
            text1={<FormattedMessage id="evapure.breeze.text1" />}
            text2={<FormattedMessage id="evapure.breeze.text2" />}
          />

          <EvaViruses
            images={[
              data.evavirus1.childImageSharp.gatsbyImageData,
              data.evavirus2.childImageSharp.gatsbyImageData,
              data.evavirus3.childImageSharp.gatsbyImageData,
            ]}
            title={<FormattedMessage id="evapure.evaviruses.title" />}
            texts={[
              <FormattedMessage id="evapure.evaviruses.text1" />,
              <FormattedMessage id="evapure.evaviruses.text2" />,
              <FormattedMessage id="evapure.evaviruses.text3" />,
            ]}
            texts2={[
              <FormattedMessage id="evapure.evaviruses.text11" />,
              <FormattedMessage id="evapure.evaviruses.text22" />,
              <FormattedMessage id="evapure.evaviruses.text33" />,
            ]}
            link={<FormattedMessage id="evapure.evaviruses.link" />}
          />
          <FAQNew />

          <PopUpAdd data={selected} targetRef={infoRef} />
          <Footer />
        </main>
      )}
    />
  );
}
