import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaInv } from '../ui/common';

const DoingsCont = styled.div`
  display: flex;
  justify-content: space-between;
  & > figure:last-child {
    & > figcaption {
      max-width: 157px;
    }
  }
  ${mediaInv.laptop`
     flex-wrap: wrap;
  `}
`;

const Doing = styled.figure`
  background: rgba(20, 26, 35, 0.1);
  border-radius: 40px;
  flex-basis: 284px;
  min-width: 284px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  & > img {
    margin: 16px auto;
  }
  & > figcaption {
    font-family: 'Craftwork Grotesk';
    font-size: 28px;
    line-height: 31px;
    font-weight: 600;
    text-align: center;
    max-width: 230px;
  }
  ${mediaInv.laptop`
   margin-bottom: 40px;
`}
  ${mediaInv.phone690`
  min-width: 100%;
`}
`;

function Doings({ images, texts }) {
  return (
    <DoingsCont>
      {images.map((image, i) => (
        <Doing>
          <img src={image} alt="benefit" />
          <figcaption>{texts[i]}</figcaption>
        </Doing>
      ))}
    </DoingsCont>
  );
}
Doings.propTypes = {
  images: PropTypes.string.isRequired,
  texts: PropTypes.string.isRequired,
};

export default Doings;
