import styled from 'styled-components';
import { media, mediaInv } from '../../Products/ui/common';
import { colors } from '../../../ui/common';

export const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
`;

export const List = styled.div`
  white-space: pre-wrap;
  margin: 64px 0 145px 0;
  .alldetailsid:first-child {
    display: block;
    h3:first-child {
      max-width: 170px;
      margin: 0.35em 0;
    }
  }

  .alldetailsid:last-child {
    display: block;
  }
  .alldetailsid:nth-child(2) {
    display: none;
  }
  ${media.medium`
  h3:first-child {
    max-width: initial !important;
    margin: 0.85em 0 !important;
  }

    h3:last-child {
      margin: 0.85em 0;
    }

  .alldetailsid:last-child {
    display: none;
  }
  .alldetailsid:nth-child(2) {
    display: block;
  }
  `}
`;

export const Block = styled.div`
  font-size: 24px;
  line-height: 28px;
  border-bottom: 2px solid ${colors.color21};
  padding: 40px 0;
  &:first-of-type {
    border-top: 2px solid ${colors.color21};
  }
  ${media.laptop`
 
  `}
`;

export const Title = styled.h3`
  font-family: 'HelveticaNeueCyr';
  font-weight: 400;
  margin: 0.85em 0;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.color19};
`;

export const Text = styled.div`
  max-height: ${(props) => (props.active ? '200vh' : 0)};
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 0.1s ease-in;
  overflow: hidden;
  color: ${colors.color01};
  line-height: 1.5;
  width: 48%;
  margin-left: auto;
  padding-left: 40px;
  border-left: 2px solid ${colors.color21};
  transform: translateY(-65px);
  margin-bottom: ${(props) => (props.active ? '-65px' : 0)};
  img {
    max-width: 465px;
  }
  div {
    font-family: 'HelveticaNeueCyr';
    font-weight: 550;
    font-size: 13px;
    line-height: 17px;
    color: ${colors.color17};
  }
  div:nth-child(even) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 14px;
  }
  div:last-child {
    margin-bottom: 0;
  }
  ${mediaInv.laptop`
width: 44%;
img {
  max-width: 200px;
}
`}
  ${mediaInv.phone690`
    width: 100%;
    padding-left: 0;
    border: 0;
    margin-bottom: 0;
    transform: translateY(0);
    div:first-child {
      margin-top: 50px;
    }
    img {
      margin: 40px 0 0 25px;
    }
`}
`;
