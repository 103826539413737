import styled from 'styled-components';
import { media } from '../Products/ui/common';

// eslint-disable-next-line import/prefer-default-export
export const Section = styled.section`
  padding: 70px 0 0 0;
  ${media.tablet`
  
  `}
  ${media.laptop`
    padding: 115px 0 0 0;
  `}
`;
