import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

function Download({ text }) {
  const data = useStaticQuery(graphql`
    query report {
      file(relativePath: { eq: "Evapolar-evaPURE-efficacy-document.pdf" }) {
        publicURL
      }
    }
  `);
  return (
    <a href={data.file.publicURL} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
}

export default Download;

Download.defaultProps = {
  text: null,
};

Download.propTypes = {
  text: PropTypes.node,
};
