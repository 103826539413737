import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContBig, BlueLink } from '../ui/common';
import Download from './download';

import { Title, ItemVirus, VirusesCont } from './styled';

function EvaViruses({ images, title, texts, texts2, link }) {
  return (
    <ContBig>
      <Title>{title}</Title>
      <VirusesCont>
        {images.map((image, i) => (
          <ItemVirus>
            <GatsbyImage
              image={image}
              loading="eager"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              alt={texts[i]}
            />
            <figcaption>
              <span className="virus1">{texts[i]}</span>
              <span className="virus2">{texts2[i]}</span>
            </figcaption>
          </ItemVirus>
        ))}
      </VirusesCont>
      <BlueLink>
        <Download text={link} />
      </BlueLink>
    </ContBig>
  );
}

export default EvaViruses;

EvaViruses.defaultProps = {
  images: [],
  texts: [],
  texts2: [],
  title: null,
  link: null,
};

EvaViruses.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  texts: PropTypes.arrayOf(PropTypes.any),
  texts2: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.node,
  link: PropTypes.node,
};
