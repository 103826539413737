import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaInv } from '../ui/common';

import { colors } from '../../../ui/common';

const InfoDesign = styled.div`
  background: rgba(3, 4, 6, 0.15);
  border-radius: 81px;
  height: 110px;
  display: flex;
  justify-content: center;
  margin: 25px 0 40px 0;
  ${mediaInv.laptop`
  background: none;
  `}
`;

const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  span {
  }

  .designInfoTitle {
    font-size: 28px;
    margin-bottom: 6px;
  }
  .designInfoTitle + span {
    max-width: 295px;
  }
  ${mediaInv.medium`
      font-size: 14px;
  `}
  ${mediaInv.phone`
     margin-top: 5px;
    .designInfoTitle + span {
       font-size: 16px;
       line-height: 20px;
     }
  `}
`;

const InfoDesignInner = styled.div`
  background: ${colors.color20};
  border-radius: 283px;
  margin: auto;
  display: inline-flex;
  align-items: center;
  padding: 8px;
  color: white;
  font-family: 'HelveticaNeueCyr';
  & > img {
    margin-right: 8px;
  }
  ${mediaInv.laptop`

  `}

  ${mediaInv.phone`
  width: 100%;
  flex-direction: column;
  text-align: center;
  border-radius: 50px;
  & > img {
    margin-right: 15px;
  }
  `}
`;

function DesignInfo({ image, text, text2 }) {
  return (
    <InfoDesign>
      <InfoDesignInner>
        <img src={image} alt="" />
        <TextCont>
          {text2 ? <span className="designInfoTitle">{text2}</span> : null}
          <span>{text}</span>
        </TextCont>
      </InfoDesignInner>
    </InfoDesign>
  );
}

export default DesignInfo;

DesignInfo.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
};
