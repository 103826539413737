import styled from 'styled-components';
import { mediaInv } from '../ui/common';
import { colors } from '../../../ui/common';

export const Title = styled.h2`
  font-family: 'Craftwork Grotesk';
  text-align: center;
  color: ${colors.color19};
  font-size: 120px;
  line-height: 124px;
  margin-bottom: 65px;
  margin-top: 195px;
  ${mediaInv.desktop`
      font-size: 72px;
      line-height: 75px;
  `}
  ${mediaInv.phone`
  font-size: 28px;
  line-height: 31px;
  margin-top: 150px;
  `}
`;

export const HowCont = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mediaInv.medium`
   flex-wrap: wrap;
`}
`;

export const BlueLinkCont = styled.div`
  margin-bottom: 40px;
`;

export const HowItem = styled.figure`
  margin: 0 0 40px 0;
  position: relative;
  width: 48%;
  figcaption {
    font-family: 'HelveticaNeueCyr';
    font-size: 16px;
    line-height: 20px;
    margin-top: 22px;
  }
  .gatsby-image-wrapper {
    height: 340px;
    border-radius: 40px;
    img {
      border-radius: 40px;
    }
  }
  ${mediaInv.medium`
    width: 100%;
    &:last-child {
      margin-bottom: 15px;
    }
`}
`;

export const HowUseNumber = styled.div`
  background: ${colors.color25};
  border-radius: 283px;
  width: 122px;
  height: 60px;
  font-family: 'HelveticaNeueCyr';
  font-size: 18px;
  color: white;
  position: absolute;
  top: 12px;
  left: 12px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span:last-child {
    background: ${colors.color18};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    margin-left: 8px;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  .CloseVideo {
    cursor: pointer;
    position: absolute;
    right: 35px;
    bottom: calc(80vh - 160px);
    z-index: 15;
    transition: 0.2s;
    display: none;
  }
  .CloseVideo.active {
    display: block;
  }
  .CloseVideo.active + iframe {
    display: block;
  }
  .CloseVideo:hover {
    opacity: 0.7;
  }
  ${mediaInv.medium`
  overflow: visible;
`}
  ${mediaInv.phone`
  .CloseVideo {
    width: 50px;
    right: 10px;
    bottom: calc(80vh - 100px);
    top: initial;
  }
`}
`;

export const PureVideo = styled.iframe`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  z-index: 10;
  border: 0;
  outline: 0;
  padding-top: 40px;
  margin-top: -40px;
  display: none;
  ${mediaInv.phone`
  padding-top: 70px;
  margin-top: -70px;
`}
`;
