import styled from 'styled-components';
import { mediaInv } from '../ui/common';
import { colors } from '../../../ui/common';

export const WhyBody = styled.div`
  background: linear-gradient(180deg, #3e454f 0%, #141a23 100%);
  border-radius: 60px;
  padding: 64px 92px;
  .whyHiddenText2 {
    display: none;
  }
  ${mediaInv.medium`
     padding: 40px 80px 25px 80px;
     border-radius: 40px 40px 0 0;
  `}
  ${mediaInv.phone690`
    padding: 40px 44px 25px 44px;
  `}
`;

export const ImgCont = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  ${mediaInv.laptop`
   .gatsby-image-wrapper {
    height: 330px;
   }
`}
  ${mediaInv.phone`
  .gatsby-image-wrapper {
    height: 310px;
   }
   flex-wrap: wrap;
   figure {
    min-width: 100%;
    margin-bottom: 24px;
   }
`}
`;

export const WhyFig = styled.figure`
  flex-basis: 49.2%;
  margin: 0;
  position: relative;
  img {
    border-radius: 40px;
  }
  figcaption {
    background: ${colors.color15};
    border-radius: 24px;
    margin-top: 16px;
    font-family: 'HelveticaNeueCyr';
    font-size: 20px;
    line-height: 24px;
    font-weight: 550;
    color: ${colors.color25};
    padding: 10px 35px;
    text-align: center;
    position: absolute;
    bottom: 18px;
    left: 18px;
    right: 18px;
    ${mediaInv.laptop`
    left: 10px;
    right: 10px;
    font-weight: 400;
    padding: 0 10px;
    height: 92px;
    display: flex;
    align-items: center;
    
`}
  }
`;

export const Title = styled.h2`
  font-family: 'Craftwork Grotesk';
  text-align: center;
  margin: 0 0 25px 0;
  color: ${colors.color15};
  font-size: 56px;
  line-height: 56px;
  ${mediaInv.medium`
  font-size: 48px;
  line-height: 51px;
  margin-bottom: 35px;
`}
  ${mediaInv.phone`
  font-weight: 550;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 25px;
`}
`;

export const About = styled.p`
  font-family: 'HelveticaNeueCyr';
  color: ${colors.color15};
  max-width: 1000px;
  text-align: center;
  margin: 35px auto 25px auto;
  font-size: 20px;
  line-height: 24px;
  span {
    display: block;
  }
  .whyHiddenText {
    display: none;
  }
  ${mediaInv.medium`
  margin: 40px auto 25px auto;
  span:last-child {
    margin-bottom: 35px;
  }
`}
  ${mediaInv.phone690`
    margin-top: 0;
  `}
`;
