import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaInv } from '../ui/common';
import { colors } from '../../../ui/common';

const BenefitsCont = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  ${mediaInv.medium`
    background: #141a23;
    margin-top: 0;
    padding: 0 80px 40px 80px;
    flex-wrap: wrap;
    border-radius: 0 0 40px 40px;
  `}
  ${mediaInv.phone690`
    padding: 0 50px 15px 50px;
  `}
`;

const Benefit = styled.div`
  background: linear-gradient(180deg, #3e454f 0%, #141a23 100%);
  border-radius: 40px;
  flex-basis: 302px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: ${colors.color15};
    font-size: 24px;
    text-align: center;
    font-family: 'HelveticaNeueCyr';
    max-width: 200px;
  }
  ${mediaInv.desktop`
  flex-basis: 23%;
  `}
  ${mediaInv.medium`
    flex-basis: 48%;
    height: 88px;
    background: #919FB4;
    border-radius: 40px;
    margin-bottom: 15px;
  `}
  ${mediaInv.phone690`
    height: 76px;
    margin-bottom: 25px;
    flex-basis: 100%;
  `}
`;

function Benefits({ texts }) {
  return (
    <BenefitsCont>
      {texts.map((text) => (
        <Benefit>
          <span>{text}</span>
        </Benefit>
      ))}
    </BenefitsCont>
  );
}
Benefits.propTypes = {
  texts: PropTypes.string.isRequired,
};

export default Benefits;
