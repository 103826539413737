import styled from 'styled-components';
import { mediaInv } from '../ui/common';

export const Section = styled.section`
  position: relative;
  margin-bottom: 200px;
  .mainPictureMob {
    display: none;
  }
  ${mediaInv.desktopLarge`
     .mainPicture {
       height: 768px;
     }
  `}
  ${mediaInv.medium`
     .mainPicture img {
      object-position: left;
     }
  `}
  ${mediaInv.phone`
    margin-bottom: 115px;
    .mainPictureMob {
      display: block;
      height: 655px;
      object-position: left;
     }
     .mainPicture {
      display: none;
     }
`}
`;

export const About = styled.div`
  position: absolute;
  left: 51%;
  top: 56%;
  transform: translateY(-50%);
  ${mediaInv.desktopLarge`
  left: initial;
  right: 40px;
  `}
  ${mediaInv.medium`
     right: initial;
     left: 20px;
  `}
  ${mediaInv.phone`
    left: 45px;
    top: 50%;
    max-width: 280px;
  `}
`;

export const AddCart = styled.div`
  display: none !important;
  position: absolute;
  left: 80px;
  bottom: 50px;
  align-items: center;
  padding-left: 24px;
  border-radius: 80px;
  height: 44px;
  background: rgba(0, 0, 0, 0.3);
  font-family: 'HelveticaNeueCyr';
  font-size: 24px;
  color: white;
  & > .AddCart__price {
    font-weight: 550;
    margin-left: 40px;
    margin-right: 16px;
  }
  & > .AddCart__self {
    background: #5f6eff;
    border-radius: 500px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 0 20px 0 16px;
    height: 100%;
    cursor: pointer;
    transition: 0.2s;
    & > img {
      margin-left: 10px;
    }
  }
  & > .AddCart__self:hover {
    transform: scale(1.03);
  }
  ${mediaInv.desktopLarge`
      bottom: 35px;
      left: 258px;
      display: none !important;
  `}
  ${mediaInv.phone690`
    display: none !important;
    display: flex;
    position: static;
    margin: 40px 8px 0 8px;
    justify-content: space-between;
    font-size: 18px;
    .AddCart__price {
      margin: 0;
    }
`}
`;

export const Title = styled.h1`
  font-family: 'Craftwork Grotesk';
  max-width: 650px;
  color: white;
  font-size: 72px;
  line-height: 75px;
  margin-bottom: 16px;
  ${mediaInv.desktopLarge`
  margin-bottom: 10px;
  margin-top: 0;
`}
  ${mediaInv.tablet`

  `}
  ${mediaInv.phone`
    font-size: 48px;
    line-height: 51px;
    margin-bottom: 16px;
  `}
  ${mediaInv.laptop`

  `}
`;

export const Text = styled.p`
  font-family: 'HelveticaNeueCyr';
  color: #fff;
  font-size: 20px;
  margin: 0;
  color: white;
  line-height: 1.85;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 24px;
  max-width: 620px;
  & + .buttonMore {
    position: relative;
    .morearrmob {
      display: none;
    }
    .morearr {
      position: absolute;
      left: 271px;
      top: 60px;
    }
    a {
      display: inline-block;
      cursor: pointer;
    }
    a:hover rect:last-child {
      fill: #8f99ff;
      stroke: #8f99ff;
    }
    img {
      backdrop-filter: blur(27px);
      border-radius: 120px;
    }
    .BmoreMob {
      display: none;
    }
    .BmoreDesk rect {
      transition: 0.3s;
    }
    .buttonMore__text {
      position: absolute;
      top: 44px;
      left: 64px;
      font-family: 'HelveticaNeueCyr';
      font-size: 16px;
      line-height: 20px;
      color: white;
      .buttonMore__text2 {
        font-size: 24px;
        line-height: 28px;
        display: block;
      }
    }
  }
  & + .buttonMore {
    & + .scrolldown {
      position: absolute;
      right: -28px;
      bottom: 0;
      cursor: pointer;
      span {
        transform: rotate(90deg);
        display: block;
        position: absolute;
        font-family: 'HelveticaNeueCyr';
        font-size: 16px;
        color: white;
        white-space: nowrap;
        top: 55px;
        left: -22px;
      }
    }
  }
  ${mediaInv.desktopLarge`
  & + .buttonMore {
    & + .scrolldown {
      right: 25px;
    }
  }
`}
  ${mediaInv.phone690`
  & + .buttonMore {
    & + .scrolldown { 
      display: none;
    }
  }
`}
  ${mediaInv.phone`
& + .buttonMore {
  .BmoreDesk {
    display: none;
  }
  .morearrmob {
    display: block;
    position: absolute;
    left: 207px;
    top: 28px;
  }
  .morearr {
    display: none;
  }
  .BmoreMob {
    display: block;
  }
  .buttonMore__text {
    top: 12px;
    left: 40px;
  }
}
`}


  ${mediaInv.laptop`

  `}
`;
