import styled from 'styled-components';
import { mediaInv } from '../Products/ui/common';

// eslint-disable-next-line import/prefer-default-export
export const Section = styled.section`
  padding: 200px 0 0 0;
  ${mediaInv.tablet`
    padding: 150px 0 0 0;
  `}
`;

export const FAQTitle = styled.h2`
  font-family: 'Craftwork Grotesk';
  font-size: 120px;
  line-height: 124px;
  margin: 0 0 60px 0;
  ${mediaInv.phone`
  font-size: 28px;
  line-height: 31px;
`}
`;
