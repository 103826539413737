import styled from 'styled-components';
import { colors } from '../../../ui/common';
import { mediaInv } from '../ui/common';

export const HeadLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 150px 0 25px 0;
  ${mediaInv.medium`
      justify-content: flex-end;
  `}
  ${mediaInv.phone690`
     flex-wrap: wrap;
     justify-content: space-between;
  `}
`;

export const AroundTitle = styled.h2`
  margin: 0;
  color: ${colors.color19};
  font-family: 'Craftwork Grotesk';
  font-size: 72px;
  line-height: 75px;
  border-right: 1px solid ${colors.color21};
  padding-right: 25px;
  span:first-child {
    margin-left: -125px;
  }
  span:last-child {
    display: block;
  }
  ${mediaInv.medium`
        font-size: 56px;
        line-height: 56px;
        span:first-child {
          margin-left: -75px;
        }
     `}
  ${mediaInv.phone690`
        overflow: hidden;
        border: 0;
        padding:0;
        width: 100%;
        font-size: 48px;
        line-height: 51px;
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(145, 159, 180, 0.85);
        margin-bottom: 25px;
        span:first-child {
          margin-left: -75px;
        }
        span:last-child {
          margin-right: -80px;
       }
    `}
`;

export const AroundLabels = styled.div`
  display: flex;
  padding-left: 25px;
  span {
    background: rgba(95, 110, 255, 0.1);
    color: ${colors.color24};
    padding: 15px 10px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s;
    border-right: 1px solid ${colors.color21};
    font-family: 'HelveticaNeueCyr';
  }
  span:last-child {
    border-radius: 0px 60px 60px 0px;
    padding: 15px 15px 15px 10px;
    border-right: 0;
  }
  span:first-child {
    border-radius: 60px 0px 0px 60px;
    padding: 15px 10px 10px 15px;
  }
  span.activeBack {
    background: ${colors.color18};
    color: white;
  }
  ${mediaInv.phone690`
        width: 100%;
        justify-content: center;
        padding-left: 0;
    `}
`;

const SliderWrapper = styled.div`
  & > * {
    height: 100%;
  }
`;
export const GalleryWrapper = styled(SliderWrapper)`
  .around-about {
    text-align: center;
    color: ${colors.color19};
    font-family: 'HelveticaNeueCyr';
    font-weight: 500;
    font-size: 24px;
    margin-top: 20px;
  }
  & .swiper-pagination {
    bottom: 70px !important;
    left: 50% !important;
    transform: translateX(-50%);
    border-radius: 41px;
    width: 76px;
    max-width: 76px;
    height: 30px;
    background: #767796;
    display: flex;
    align-items: center;
    justify-content: center;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: white;
      opacity: 1;
      border: 1px solid #fff;
    }
    .swiper-pagination-bullet-active {
      background-color: ${colors.color18};
    }
  }
  .swiper-slide {
    height: 575px;
    border-radius: 40px;
  }

  .swiper-slide img {
    max-width: 100%;
    height: 91%;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
  }

  ${mediaInv.medium`
  .swiper-pagination {
    bottom: 105px !important;
  }
   .swiper-slide {
    height: 610px;
    border-radius: 40px;
    img {
      border-radius: 40px;
      height: 525px;
    }
   }
`}
`;
