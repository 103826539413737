import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleNew from '../../Products/ui/ToggleNew';
import { List, Block, Row, Title, Text } from './styled';

function InDetailsList({ questions }) {
  const [active, setActive] = useState(null);
  const handleClick = (index) => {
    setActive(active === index ? null : index);
  };

  return (
    <List>
      {questions.map(({ title, content }, questionIndex) => (
        <Block
          // eslint-disable-next-line react/no-array-index-key
          key={questionIndex}
          active={active === questionIndex}
          className="blockToggle"
        >
          <Row>
            <Title>{title}</Title>
            <ToggleNew
              onClick={() => handleClick(questionIndex)}
              active={active === questionIndex}
            />
          </Row>
          <Text active={active === questionIndex}>
            {content.map((contentChunk) => contentChunk)}
          </Text>
        </Block>
      ))}
    </List>
  );
}

InDetailsList.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      content: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    }).isRequired
  ).isRequired,
};

export default InDetailsList;
