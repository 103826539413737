import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import cart from './icons/cart.svg';
import scroll from './icons/scroll.svg';
import morearr from './icons/morearr.svg';
import morearrmob from './icons/morearrmob.svg';

import { Section, About, Title, Text, AddCart } from './styled';

function BuyTop({
  data,
  targetRef,
  image,
  imagemob,
  title,
  text,
  text1,
  text2,
  text3,
  text4,
}) {
  const targetOffset = 500;
  const onClick = () => {
    window.scrollTo(0, targetRef.current.offsetTop - targetOffset);
  };

  const { price = '' } = data;
  return (
    <Section>
      <GatsbyImage image={image} alt="product" className="mainPicture" />
      <GatsbyImage image={imagemob} alt="product" className="mainPictureMob" />
      <About>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <div className="buttonMore">
          <a href="#product-card1">
            <img src={morearr} alt="" className="morearr" />
            <img src={morearrmob} alt="" className="morearrmob" />
            <svg
              width="347"
              height="136"
              viewBox="0 0 347 136"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="BmoreDesk"
            >
              <g filter="url(#filter0_b_1569_7548)">
                <rect
                  width="347"
                  height="136"
                  rx="68"
                  fill="#434DB3"
                  fillOpacity="0.15"
                />
                <path
                  d="M272 69H283.17L278.29 73.88C277.9 74.27 277.9 74.91 278.29 75.3C278.68 75.69 279.31 75.69 279.7 75.3L286.29 68.71C286.68 68.32 286.68 67.69 286.29 67.3L279.71 60.7C279.32 60.31 278.69 60.31 278.3 60.7C277.91 61.09 277.91 61.72 278.3 62.11L283.17 67H272C271.45 67 271 67.45 271 68C271 68.55 271.45 69 272 69Z"
                  fill="white"
                />
                <rect
                  x="211.5"
                  y="0.5"
                  width="135"
                  height="135"
                  rx="67.5"
                  stroke="#919FB4"
                  strokeOpacity="0.85"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_1569_7548"
                  x="-54"
                  y="-54"
                  width="455"
                  height="244"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="27" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_1569_7548"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_1569_7548"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <svg
              width="251"
              height="72"
              viewBox="0 0 251 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="BmoreMob"
            >
              <g filter="url(#filter0_b_1570_6907)">
                <rect
                  width="251"
                  height="72"
                  rx="36"
                  fill="#434DB3"
                  fillOpacity="0.15"
                />
                <path
                  d="M208 37H219.17L214.29 41.88C213.9 42.27 213.9 42.91 214.29 43.3C214.68 43.69 215.31 43.69 215.7 43.3L222.29 36.71C222.68 36.32 222.68 35.69 222.29 35.3L215.71 28.7C215.32 28.31 214.69 28.31 214.3 28.7C213.91 29.09 213.91 29.72 214.3 30.11L219.17 35H208C207.45 35 207 35.45 207 36C207 36.55 207.45 37 208 37Z"
                  fill="white"
                />
                <rect
                  x="179.5"
                  y="0.5"
                  width="71"
                  height="71"
                  rx="35.5"
                  stroke="#919FB4"
                  strokeOpacity="0.85"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_1570_6907"
                  x="-54"
                  y="-54"
                  width="359"
                  height="180"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="27" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_1570_6907"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_1570_6907"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <div className="buttonMore__text">
              <span className="buttonMore__text1">{text1}</span>
              <span className="buttonMore__text2">{text2}</span>
            </div>
          </a>
        </div>
        <a href="#product-card1" className="scrolldown">
          <img src={scroll} alt="scrolldown" />
          <span>{text3}</span>
        </a>
      </About>
      <AddCart onClick={onClick}>
        <span className="AddCart__title">{text1}</span>
        <span className="AddCart__price">{price}</span>
        <span className="AddCart__self">
          {text4}
          <img src={cart} alt="cart" />
        </span>
      </AddCart>
    </Section>
  );
}

export default BuyTop;

BuyTop.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  imagemob: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text: PropTypes.node,
  text1: PropTypes.node,
  text2: PropTypes.node,
  text3: PropTypes.node,
  text4: PropTypes.node,
  data: PropTypes.objectOf(PropTypes.any),
  targetRef: PropTypes.objectOf(PropTypes.any),
};

BuyTop.defaultProps = {
  image: {},
  imagemob: {},
  data: {},
  targetRef: {},
  title: null,
  text: null,
  text1: null,
  text2: null,
  text3: null,
  text4: null,
};
