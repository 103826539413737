import React from 'react';
import InDetails from '../../InDetails';
import image from '../../../images/product-page/evapure/evadim1.png';
import imageMob from '../../../images/product-page/evapure/evadim2.png';

export default function Details() {
  const list = [
    {
      title: 'evapure.indetails.1.title1',
      content: [
        { id: 'evapure.indetails.1.text1' },
        { id: 'evapure.indetails.1.text2' },
        { id: 'evapure.indetails.1.text3' },
        { id: 'evapure.indetails.1.text4' },
        { id: 'evapure.indetails.1.text5' },
        { id: 'evapure.indetails.1.text6' },
        { id: 'evapure.indetails.1.text7' },
        { id: 'evapure.indetails.1.text8' },
        { id: 'evapure.indetails.1.text9' },
        { id: 'evapure.indetails.1.text10' },
        { id: 'evapure.indetails.1.text11' },
        { id: 'evapure.indetails.1.text12' },
        { id: 'evapure.indetails.1.text13' },
        { id: 'evapure.indetails.1.text14' },
        { id: 'evapure.indetails.1.text15' },
        { id: 'evapure.indetails.1.text16' },
        { id: 'evapure.indetails.1.text17' },
        { id: 'evapure.indetails.1.text18' },
        { id: 'evapure.indetails.1.text19' },
        { id: 'evapure.indetails.1.text20' },
        { id: 'evapure.indetails.1.text21' },
        { id: 'evapure.indetails.1.text22' },
        { id: 'evapure.indetails.1.text23' },
        { id: 'evapure.indetails.1.text24' },
        { id: 'evapure.indetails.1.text25' },
        { id: 'evapure.indetails.1.text26' },
        { id: 'evapure.indetails.1.text27' },
        { id: 'evapure.indetails.1.text28' },
      ],
    },
    {
      title: 'evapure.indetails.1.title2',
      content: [{ img: image }],
    },
    {
      title: 'evapure.indetails.1.title2',
      content: [{ img: imageMob }],
    },
  ];
  return <InDetails questions={list} />;
}
