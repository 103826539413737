import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContBig } from '../ui/common';

import { Title, Text, ImgCont } from './styled';

function EvaBreeze({ image, title, title2, text1, text2 }) {
  return (
    <ContBig>
      <Title>
        {title}
        <sup>®</sup> {title2}
      </Title>
      <Text>
        <p>{text1}</p>
        <p>{text2}</p>
      </Text>
      <ImgCont>
        <GatsbyImage image={image} alt={title} />
      </ImgCont>
    </ContBig>
  );
}

export default EvaBreeze;

EvaBreeze.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  title2: PropTypes.node,
  text1: PropTypes.node,
  text2: PropTypes.node,
};

EvaBreeze.defaultProps = {
  image: {},
  title: null,
  title2: null,
  text1: null,
  text2: null,
};
