import styled from 'styled-components';
import { mediaInv } from '../ui/common';
import { colors } from '../../../ui/common';

export const Title = styled.h2`
  font-family: 'Craftwork Grotesk';
  color: ${colors.color19};
  font-size: 56px;
  line-height: 56px;
  margin: 200px auto 40px auto;
  text-align: center;
  max-width: 1000px;
  ${mediaInv.phone`
  font-size: 28px;
  line-height: 31px;
  margin-top: 150px;
  `}
`;

export const ImgCont = styled.div`
  border-radius: 40px;
  border: 1px solid #6170fd;
  img {
    border-radius: 40px;
  }
  ${mediaInv.medium`
  .gatsby-image-wrapper {
    height: 339px;
  }
  `}
  ${mediaInv.phone`
  .gatsby-image-wrapper {
    height: 230px;
  }
  `}
`;

export const Text = styled.div`
  font-family: 'HelveticaNeueCyr';
  color: ${colors.color17};
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin: auto;
  p:first-child {
    max-width: 500px;
    margin: auto;
  }
  p:last-child {
    max-width: 800px;
    font-size: 20px;
    line-height: 24px;
    margin: 25px auto 40px auto;
  }
`;
