import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ContBig } from '../Products/ui/common';
import InDetailsList from './List';
import { Section, FAQTitle } from './styled';

function Details({ questions }) {
  return (
    <Section>
      <ContBig>
        <FAQTitle>
          <FormattedMessage id="evapure.faq.supertitle" />
        </FAQTitle>
        <InDetailsList
          questions={questions.map(({ title, content }) => ({
            title: <FormattedMessage id={title} />,
            content: content.map((chunk, i) =>
              chunk.img ? (
                <img
                  src={chunk.img}
                  alt="Evaporative cooling diagram"
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                />
              ) : (
                <div>
                  <FormattedMessage
                    id={chunk.id}
                    values={chunk.values}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                  />
                </div>
              )
            ),
          }))}
        />
      </ContBig>
    </Section>
  );
}

Details.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          img: PropTypes.bool,
          id: PropTypes.string,
          // eslint-disable-next-line react/forbid-prop-types
          values: PropTypes.any,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
};

export default Details;
