import React from 'react';
import FAQNew from '../../FAQNew';

export default function Details() {
  const list = [
    {
      title: 'evapure.faq.title1',
      content: [
        { id: 'evapure.faq1.text1' },
        { id: 'evapure.faq1.text2' },
        { id: 'evapure.faq1.text3' },
        { id: 'evapure.faq1.text4' },
      ],
    },
    {
      title: 'evapure.faq.title2',
      content: [{ id: 'evapure.faq2.text1' }, { id: 'evapure.faq2.text2' }],
    },
    {
      title: 'evapure.faq.title3',
      content: [{ id: 'evapure.faq3.text1' }, { id: 'evapure.faq3.text2' }],
    },
    {
      title: 'evapure.faq.title4',
      content: [{ id: 'evapure.faq4.text1' }],
    },
    {
      title: 'evapure.faq.title5',
      content: [{ id: 'evapure.faq5.text1' }],
    },
    {
      title: 'evapure.faq.title6',
      content: [{ id: 'evapure.faq6.text1' }],
    },
  ];
  return <FAQNew questions={list} />;
}
