import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { ContBigWhy, BlueLink } from '../ui/common';
import Download from './download';
import Benefits from './benefits';

import { Title, About, WhyBody, ImgCont, WhyFig } from './styled';

configureAnchors({ offset: -30, scrollDuration: 150 });

function WhyEffective({
  image1,
  image2,
  title,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  text9,
  link,
}) {
  return (
    <ContBigWhy id="hydrogen-peroxide">
      <ScrollableAnchor id="hydrogen-peroxide1">
        <WhyBody>
          <Title>{title}</Title>
          <ImgCont>
            <WhyFig>
              <GatsbyImage image={image1} alt="why1" />
              <figcaption>{text2}</figcaption>
            </WhyFig>
            <WhyFig>
              <GatsbyImage image={image2} alt="why2" />
              <figcaption>{text3}</figcaption>
            </WhyFig>
          </ImgCont>
          <About>
            <span className="whyHiddenText">{text8}</span>
            <span>{text1}</span>
          </About>
          <div className="whyHiddenText2">{text9}</div>
          <BlueLink>
            <Download text={link} />
          </BlueLink>
        </WhyBody>
      </ScrollableAnchor>

      <Benefits texts={[text4, text5, text6, text7]} />
    </ContBigWhy>
  );
}

export default WhyEffective;

WhyEffective.propTypes = {
  image1: PropTypes.objectOf(PropTypes.any),
  image2: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text1: PropTypes.node,
  text2: PropTypes.node,
  text3: PropTypes.node,
  text4: PropTypes.node,
  text5: PropTypes.node,
  text6: PropTypes.node,
  text7: PropTypes.node,
  text8: PropTypes.node,
  text9: PropTypes.node,
  link: PropTypes.node,
};

WhyEffective.defaultProps = {
  image1: {},
  image2: {},
  title: null,
  text1: null,
  text2: null,
  text3: null,
  text4: null,
  text5: null,
  text6: null,
  text7: null,
  text8: null,
  text9: null,
  link: null,
};
