/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from '../ui/common';
import CloseVideo from './icons/closevid.svg';

import {
  Title,
  Text,
  BenefitVideo,
  BenButton,
  BenefitLink,
  VideoContainer,
  PureVideo,
} from './styled';

function Benefit({ image, title, text, link }) {
  const [active, setActive] = useState(null);
  const goTo = useRef(null);

  return (
    <VideoContainer ref={goTo}>
      <img
        src={CloseVideo}
        alt=""
        className={active ? 'CloseVideo active' : 'CloseVideo'}
        onClick={() => setActive(!active)}
        onKeyPress
      />
      <PureVideo
        src={
          active
            ? 'https://www.youtube.com/embed/3reVz8l2w_o?autoplay=1&enablejsapi=1'
            : null
        }
        width="100%"
        height="100%"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <Container>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <BenefitVideo>
          <GatsbyImage image={image} alt="" />
          <BenButton
            onClick={() => {
              setActive(!active);
              goTo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }}
          >
            <span>Play Showreel</span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.90963 6.10224C8.51561 5.82804 8 6.13756 8 6.6483V13.3517C8 13.8624 8.51561 14.172 8.90963 13.8978L13.7259 10.5461C14.0914 10.2917 14.0914 9.70828 13.7259 9.45394L8.90963 6.10224Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill="white"
              />
            </svg>
          </BenButton>
        </BenefitVideo>
        <BenefitLink href="#hydrogen-peroxide1">{link}</BenefitLink>
      </Container>
    </VideoContainer>
  );
}

export default Benefit;

Benefit.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text: PropTypes.node,
  link: PropTypes.node,
};

Benefit.defaultProps = {
  image: {},
  title: null,
  text: null,
  link: null,
};
