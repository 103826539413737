/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContBig, BlueLink } from '../ui/common';
import DesignInfo from './DesignInfo';
import WarningIcon from './icons/warning.svg';
import CloseVideo from './icons/closevid.svg';

import {
  Title,
  HowCont,
  HowItem,
  HowUseNumber,
  BlueLinkCont,
  VideoContainer,
  PureVideo,
} from './styled';

function HowUse({ images, title, HowTexts, link, text, text2, step }) {
  const [active, setActive] = useState(null);
  const goTo = useRef(null);

  return (
    <VideoContainer>
      <img
        src={CloseVideo}
        alt=""
        className={active ? 'CloseVideo active' : 'CloseVideo'}
        onClick={() => setActive(!active)}
      />
      <PureVideo
        ref={goTo}
        src={
          active
            ? 'https://www.youtube.com/embed/qk_lYWJu2N4?autoplay=1&enablejsapi=1'
            : null
        }
        width="100%"
        height="80vh"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <ContBig>
        <Title>{title}</Title>
        <HowCont>
          {images.map((image, i) => (
            <HowItem>
              <GatsbyImage
                image={image}
                loading="eager"
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                alt={title}
              />
              <figcaption>{HowTexts[i]}</figcaption>
              <HowUseNumber>
                <span>{step}</span>
                <span>{i + 1}</span>
              </HowUseNumber>
            </HowItem>
          ))}
        </HowCont>
        <BlueLinkCont>
          <BlueLink
            onClick={() => {
              setActive(!active);
              setTimeout(() => {
                goTo.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }, 0);
            }}
          >
            {link}
          </BlueLink>
        </BlueLinkCont>
        <DesignInfo image={WarningIcon} text={text2} text2={text} />
      </ContBig>
    </VideoContainer>
  );
}

export default HowUse;

HowUse.propTypes = {
  images: PropTypes.objectOf(PropTypes.any),
  HowTexts: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  step: PropTypes.node,
  link: PropTypes.node,
  text: PropTypes.node,
  text2: PropTypes.node,
};

HowUse.defaultProps = {
  images: {},
  HowTexts: {},
  title: null,
  link: null,
  step: null,
  text: null,
  text2: null,
};
