import styled from 'styled-components';
import { mediaInv } from '../../Products/ui/common';
import { colors } from '../../../ui/common';

export const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
`;

export const List = styled.div`
  white-space: pre-wrap;
  margin: 64px 0 130px 0;
  .blockToggle:first-child {
    div:first-child + div,
    div:first-child + div + div {
      text-indent: -15px;
      padding-left: 22px;
    }
    div:first-child,
    div:last-child {
      padding-left: 0 !important;
      text-indent: 0 !important;
    }
  }
`;

export const Block = styled.div`
  font-size: 24px;
  line-height: 28px;
  padding: 24px 0;
  border-bottom: 2px solid ${colors.color21};
  &:first-of-type {
    border-top: 2px solid ${colors.color21};
  }
  button {
    left: initial;
    right: 0;
    top: -2px;
  }
  ${mediaInv.phone`
  button svg {
    width: 40px;
  }
  button {
    top: -22px;
  }
  `}
`;

export const Title = styled.h3`
  font-family: 'HelveticaNeueCyr';
  font-weight: 400;
  margin: 0.85em 0;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.color19};
  ${mediaInv.laptop`
     max-width: 500px;
     transform: translateY(-20px);
  `}
  ${mediaInv.phone`
     transform: translateY(0);
     margin: 0;
     max-width: calc(100% - 50px);
  `}
`;

export const Text = styled.div`
  max-height: ${(props) => (props.active ? '200vh' : 0)};
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 0.1s ease-in;
  overflow: hidden;
  max-width: 1170px;
  line-height: 1.5;
  div {
    font-family: 'HelveticaNeueCyr';
    font-size: 20px;
    line-height: 24px;
    color: ${colors.color17};
    margin-bottom: 30px;
  }
  div:last-child {
    margin-bottom: 0;
  }
  ${mediaInv.laptop`
     max-width: 500px;
  `}
  ${mediaInv.phone`
     max-width: calc(100% - 50px);
     div:first-child {
      margin-top: 24px;
     }
  `}
`;
