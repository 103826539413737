import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import { ContBig } from '../ui/common';
import image1 from '../../../images/product-page/evapure/around1.png';
import image2 from '../../../images/product-page/evapure/around2.png';
import image3 from '../../../images/product-page/evapure/around3.png';

import { GalleryWrapper, HeadLine, AroundTitle, AroundLabels } from './styled';

const images = [image1, image2, image3];

function AlwaysAround({ title1, title2, aroundTexts, textSlides }) {
  const [swiper, setSwiper] = useState(null);

  const goNext = (index) => {
    if (swiper !== null) {
      swiper.slideTo(index);
    }
  };

  const [active, setActive] = useState(0);
  const handleClick = (i) => {
    setActive(i);
    goNext(i);
  };

  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    on: {
      slideChangeTransitionEnd() {
        const activeSlide = document.querySelectorAll(
          '.swiper-slide-active'
        )[2];
        const str = activeSlide.querySelector('img').getAttribute('src');
        let num = 0;
        if (str.indexOf('around2') > 0) {
          num = 1;
        } else if (str.indexOf('around3') > 0) {
          num = 2;
        }
        handleClick(num);
      },
      init() {
        setInterval(() => {
          const bulletFirst = document.querySelector(
            '.swiper-pagination-bullet:first-child'
          );
          const bulletActiveNext = document.querySelector(
            '.swiper-pagination-bullet-active'
          ).nextElementSibling;
          if (
            bulletActiveNext &&
            bulletActiveNext.classList.contains('swiper-pagination-bullet')
          ) {
            bulletActiveNext.click();
          } else {
            bulletFirst.click();
          }
        }, 4000);
      },
    },
    spaceBetween: 10,
  };

  return (
    <ContBig className="sliderContBig">
      <HeadLine>
        <AroundTitle>
          <span>{title1}</span>
          <span>{title2}</span>
        </AroundTitle>
        <AroundLabels>
          {aroundTexts.map((text, i) => (
            <span
              role="button"
              tabIndex={0}
              className={i === active ? 'activeBack' : null}
              onClick={() => handleClick(i)}
              onKeyDown={() => handleClick(i)}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              {text}
            </span>
          ))}
        </AroundLabels>
      </HeadLine>
      <GalleryWrapper>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Swiper getSwiper={setSwiper} {...params}>
          {/* eslint-disable-next-line react/no-array-index-key */}
          {images.map((image, i) => (
            <div className="swiper-slide">
              <img src={image} alt="" />
              {/* eslint-disable-next-line react/no-array-index-key */}
              <div className="around-about">{textSlides[i]}</div>
            </div>
          ))}
        </Swiper>
      </GalleryWrapper>
    </ContBig>
  );
}

AlwaysAround.defaultProps = {
  images: [],
  title1: null,
  title2: null,
  aroundTexts: [],
  textSlides: [],
};

AlwaysAround.propTypes = {
  // eslint-disable-next-line react/require-default-props
  images: PropTypes.arrayOf(PropTypes.any),
  aroundTexts: PropTypes.arrayOf(PropTypes.any),
  textSlides: PropTypes.arrayOf(PropTypes.any),
  title1: PropTypes.node,
  title2: PropTypes.node,
};

export default AlwaysAround;
