import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { mediaInv } from './common';

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  position: absolute;
  left: 39.5%;
  top: 0;
  svg {
    transition: transform 0.25s ease-in-out;
    transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
    display: block;
  }
  ${mediaInv.medium`
     left: 172px;
  `}
`;

function ToggleNew({ onClick, active, chillBlue }) {
  return (
    <Button
      aria-label={active ? 'Hide info' : 'Show info'}
      chillBlue={chillBlue}
      type="button"
      onClick={onClick}
      active={active}
    >
      <PlusIcon />
    </Button>
  );
}
export default ToggleNew;

export function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
    >
      <path
        d="M34.9988 29V40.17L30.1188 35.29C29.7288 34.9 29.0888 34.9 28.6987 35.29C28.3088 35.68 28.3088 36.31 28.6987 36.7L35.2888 43.29C35.6788 43.68 36.3087 43.68 36.6987 43.29L43.2888 36.7C43.6788 36.31 43.6788 35.68 43.2888 35.29C42.8988 34.9 42.2687 34.9 41.8787 35.29L36.9987 40.17V29C36.9987 28.45 36.5487 28 35.9988 28C35.4488 28 34.9988 28.45 34.9988 29Z"
        fill="#272C34"
      />
      <rect
        x="0.5"
        y="0.5"
        width="71"
        height="71"
        rx="35.5"
        stroke="#919FB4"
        strokeOpacity="0.85"
      />
    </svg>
  );
}

ToggleNew.propTypes = {
  onClick: PropType.func,
  active: PropType.bool,
  chillBlue: PropType.bool,
};

ToggleNew.defaultProps = {
  onClick: null,
  active: false,
  chillBlue: false,
};
